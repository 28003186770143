import React, {useEffect, useState} from "react";
import { collection, query, where, getDocs , doc, getDoc} from "firebase/firestore"; // Import necessary Firestore functions
import {db} from "../Firebase/firebase";
import bgLogoHole from "../Images/bg-logo-hole.png";
import goldenImage from "../Images/goldern group.png";
import whatsAppLogo from "../Images/whatsapp.png";
import telegramLogo from "../Images/telegramlogo.png";
import border from "../Images/border.jpeg";
import {Link} from "react-router-dom"

export default function MainPage(){
    const [draws, setDraws] = useState([]);
    const [mumbaiPatternDraws, setMumbaiPatternDraws] = useState([]);
    const [delhiPatternDraws, setDelhiPatternDraws] = useState([]);
    const [dates, setDates] = useState([]);
    const [topSixMumbaiDraws, setTopSixMumbaiDraws] = useState([]);
    const [todaysDraws, setTodaysDraws] = useState([]);
    const [upcomingDraws, setUpcomingDraws] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchDrawsForDates = async () => {
        console.log("Request")
        try {
            const currentDate = new Date();
            const sevenDaysAgo = new Date(currentDate.getTime() -  24 * 60 * 60 * 1000); // 7 days ago
            const currentDatePlusOne = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000); // current date + 1 day

            const datesToFetch = generateDatesToFetch(currentDate, 1, 1);
            const convertedDates = datesToFetch.map(date => date.replace(/-/g, "/"));
            setDates(convertedDates);

            const allDrawsRef = collection(db, "all-draws-beta");
            const queryRef = query(allDrawsRef, where("timestamp", ">", sevenDaysAgo), where("timestamp", "<", currentDatePlusOne));
            const querySnapshot = await getDocs(queryRef);

            const allDrawsData = querySnapshot.docs.map(doc => doc.data());

            const mumbaiPatternDrawsData = await fetchPatternDraws("mumbai-pattern-draws");
            const delhiPatternDrawsData = await fetchPatternDraws("delhi-pattern-draws");

            setMumbaiPatternDraws(mumbaiPatternDrawsData);
            setDelhiPatternDraws(delhiPatternDrawsData);

            const [mumbaiDrawsFetch, delhiDrawsFetch] = separateDrawsByPattern(allDrawsData);

            sortDrawsByStartTime(mumbaiDrawsFetch);
            sortDrawsByStartTime(delhiDrawsFetch);

            const fetchedDraws = convertedDates.map(date => {
                const mumbaiDraws = filterDrawsByDate(mumbaiDrawsFetch, date);
                const delhiDraws = filterDrawsByDate(delhiDrawsFetch, date);

                return {
                    date,
                    mumbaiDraws,
                    delhiDraws
                };
            });

            setDraws(fetchedDraws.reverse());

            console.log(fetchedDraws.reverse());
        } catch (error) {
            // Handle errors
            console.error('Error fetching draws:', error);
        }
    };

    const fetchPatternDraws = async (patternDrawsCollection) => {
        const patternDrawsRef = collection(db, patternDrawsCollection);
        const patternDrawsSnapshot = await getDocs(patternDrawsRef);
        const patternDrawsData = patternDrawsSnapshot.docs.map(doc => doc.data()).sort((a, b) => {
            console.log(a.id)
            return convertStringToTime(a.startTime) - convertStringToTime(b.startTime);

        });

        return patternDrawsData;
    };

    const separateDrawsByPattern = (drawsData) => {
        const mumbaiDraws = drawsData.filter(draw => draw.pattern === "mumbaiPattern");
        const delhiDraws = drawsData.filter(draw => draw.pattern === "delhiPattern");

        return [mumbaiDraws, delhiDraws];
    };

    const sortDrawsByStartTime = (draws) => {
        draws.sort((a, b) => convertStringToTime(a.startTime) - convertStringToTime(b.startTime));
    };

    const filterDrawsByDate = (draws, date) => {
        return draws.filter(draw => draw.date === date);
    };

    const getTopSixMumbaiDraws = async () => {
        const topSixDraws = [];

        const drawIds = [
            "good-morning",
            "bombay-exp.",
            "bombay-exp",
            "golden",
            "kalyan",
            "goldstar",
            "golden-night"
        ];

        // Mumbai Pattern Draws

        for (const drawId of drawIds) {
            const drawData = mumbaiPatternDraws.find(draw => draw.id === drawId);
            if (drawData) {
                topSixDraws.push(drawData);
            }
        }

        setTopSixMumbaiDraws(topSixDraws);
    };

    const fetchUpcomingDraws = () => {
        if (todaysDraws && todaysDraws.length > 0) {
            const currentTime = new Date();
            const timeThreshold = 30 * 60 * 1000; // 20 minutes in milliseconds

            const filterUpcomingDraws = (draws) => {
                return draws.filter(draw => {
                    const startTime = convertStringToTime(draw.startTime);
                    const closingTime = convertStringToTime(draw.closingTime);
                    const timeDifferenceOpening = startTime - currentTime;
                    const timeDifferenceClosing = closingTime - currentTime;

                    return (
                        (timeDifferenceOpening <= timeThreshold && timeDifferenceOpening > -30 * 60 * 1000) ||
                        (timeDifferenceClosing <= timeThreshold && timeDifferenceClosing > -30 * 60 * 1000)
                    );
                }).map(draw => ({
                    ...draw,
                    selectedDraw: (convertStringToTime(draw.startTime) - currentTime) <= timeThreshold ? "opening" : "closing"
                }));
            };


            const mumbaiPatternUpcomingDraws = filterUpcomingDraws(mumbaiPatternDraws, "closing");
            const delhiPatternUpcomingDraws = filterUpcomingDraws(delhiPatternDraws, "closing");

            const upcomingDraws = [...mumbaiPatternUpcomingDraws, ...delhiPatternUpcomingDraws];
            setUpcomingDraws(upcomingDraws);

            // Remove duplicates based on draw id and keep the one with the lowest time
            const uniqueUpcomingDraws = upcomingDraws.reduce((uniqueDraws, draw) => {
                const existingDraw = uniqueDraws.find(uniqueDraw => uniqueDraw.id === draw.id);
                if (!existingDraw || convertStringToTime(draw.startTime) < convertStringToTime(existingDraw.startTime)) {
                    return [...uniqueDraws.filter(uniqueDraw => uniqueDraw.id !== draw.id), draw];
                }
                return uniqueDraws;
            }, []);

            setUpcomingDraws(uniqueUpcomingDraws);
        }
    };

    function convertStringToTime(inputString) {
        const timeParts = inputString.split(":");

        if (timeParts.length !== 2) {
            throw new Error("Invalid time format");
        }

        const hours = parseInt(timeParts[0]);
        const minutes = parseInt(timeParts[1]);

        if (isNaN(hours) || isNaN(minutes) || hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
            throw new Error("Invalid time values");
        }

        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);

        return date;
    }

    const fetchDrawsSomeFunction = () => {
        const currentDate = new Date();
        const formattedCurrentDate = formatDate(currentDate);
        const yesterday = new Date(currentDate);
        yesterday.setDate(yesterday.getDate() - 1);
        const formattedYesterdayDate = formatDate(yesterday);
        // convert formattedCurrentDate date from 18-10-2023 to 18/10/2023
        const convertedCurrentDate = formattedCurrentDate.replace(/-/g, "/");
        const convertedYesterdayDate = formattedYesterdayDate.replace(/-/g, "/");

        let drawsToShow = [];
        let currentDayDraws = [];

        currentDayDraws = draws.filter(draw => draw.date === convertedCurrentDate);

        if(currentDayDraws.length) {
            if (currentDayDraws[0].mumbaiDraws.length > 0 || currentDayDraws[0].delhiDraws.length > 0) {
                currentDayDraws[0].mumbaiDraws.sort((a, b) => {
                    const aTime = convertStringToTime(a.startTime);
                    const bTime = convertStringToTime(b.startTime);
                    return aTime - bTime;
                })
                currentDayDraws[0].delhiDraws.sort((a, b) => {
                    const aTime = convertStringToTime(a.startTime);
                    const bTime = convertStringToTime(b.startTime);
                    return aTime - bTime;
                })
                setTodaysDraws(currentDayDraws);
            } else {
                drawsToShow = draws.filter(draw => draw.date === convertedYesterdayDate);
                currentDayDraws[0].mumbaiDraws.sort((a, b) => {
                    const aTime = convertStringToTime(a.startTime);
                    const bTime = convertStringToTime(b.startTime);
                    return aTime - bTime;
                })
                currentDayDraws[0].delhiDraws.sort((a, b) => {
                    const aTime = convertStringToTime(a.startTime);
                    const bTime = convertStringToTime(b.startTime);
                    return aTime - bTime;
                })
                setTodaysDraws(drawsToShow);
            }
        }
    }

    useEffect(() => {
        if (todaysDraws && todaysDraws.length > 0) {
            fetchUpcomingDraws();
        }
    }, [todaysDraws]);

    useEffect(() => {
        getTopSixMumbaiDraws();
    }, [mumbaiPatternDraws]);

    useEffect(() => {
        fetchDrawsForDates();
    }, []);

    useEffect(() => {
        fetchDrawsSomeFunction()
    }, [draws]);

    const twentyFourHourToTwelveHourCovert = (string) => {
        const [hours, minutes] = string.split(":");
        const formattedHours = hours.padStart(2, '0');
        const formattedMinutes = minutes.padStart(2, '0');
        const period = formattedHours >= 12 ? 'PM' : 'AM';
        const displayHours = formattedHours > 12 ? formattedHours - 12 : formattedHours;
        return `${displayHours}:${formattedMinutes} ${period}`;
    }

    const generateDatesToFetch = (currentDate, daysBehind, daysAhead) => {
        const datesToFetch = [];
        const today = new Date(currentDate);

        for (let i = daysBehind; i > 0; i--) {
            const previousDate = new Date(today);
            previousDate.setDate(today.getDate() - i);
            datesToFetch.push(formatDate(previousDate));
        }

        datesToFetch.push(formatDate(today));

        for (let i = 1; i <= daysAhead; i++) {
            const nextDate = new Date(today);
            nextDate.setDate(today.getDate() + i);
            datesToFetch.push(formatDate(nextDate));
        }

        return datesToFetch;
    };

    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const refreshData = async () => {
        // window.location.reload();

        setLoading(true)

        if (todaysDraws && todaysDraws.length > 0) {
            fetchUpcomingDraws();
        }
        await getTopSixMumbaiDraws();
        await fetchDrawsForDates();
        fetchDrawsSomeFunction()

        setTimeout(function () {
            setLoading(false)
        }, 500)

    };

    const LoadingSpinner = () => {
        return (
            <div className="flex justify-center backdrop-blur-lg backdrop-saturate-150 items-center z-50 fixed w-screen bg-black/30 h-screen">
                <div className="animate-spin rounded-full h-28 w-28 border-t-4 border-b-4 border-yellow-400"></div>
            </div>
        );
    };

    const [whatsappNumbersList, setWhatsAppNumbersList] = useState([]);

    // const getConfig = async () => {
    //     const configDocRef = doc(db, 'config', 'whatsAppNumbersWeb');
    //     const configDocSnapshot = await getDoc(configDocRef);
    //
    //     if (configDocSnapshot.exists()) {
    //         const configData = configDocSnapshot.data();
    //         const whatsappNumbersObject = configData['whatsapp_numbers_list_web'] || {};
    //
    //         // Convert object to array of key-value pairs
    //         const whatsappNumbersArray = Object.entries(whatsappNumbersObject).map(([name, number]) => ({ name, number }));
    //         setWhatsAppNumbersList(whatsappNumbersArray);
    //         console.log(whatsappNumbersArray[0])
    //     } else {
    //         console.log('Document does not exist');
    //     }
    // };


    const mapKeys = (obj) => {
        const newObj = {};
        for (const key in obj) {
            const newKey = key.replace(/-/g, '_');
            if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                newObj[newKey] = mapKeys(obj[key]);
            } else {
                newObj[newKey] = obj[key];
            }
        }
        return newObj;
    };


    const getConfig = async () => {
        try {
            const configDocSnapshot = await getDoc(doc(db, 'config', 'dpanna-main'));
            if (configDocSnapshot.exists()) {
                const configData = configDocSnapshot.data();
                const mappedConfigData = mapKeys(configData);

                // Transform the whatsAppNumbers map to an array of objects
                const numbersList = Object.entries(mappedConfigData.whatsAppNumbers).map(([name, number]) => ({
                    name,
                    number
                }));

                setWhatsAppNumbersList(numbersList);
            } else {
                console.log('Document does not exist');
            }
        } catch (error) {
            console.error('Error fetching config:', error);
        }
    };


    useEffect(() => {
        getConfig()
    },[])

    return (
        <>
            {loading && <LoadingSpinner />}
            <div className="flex flex-col items-center bg-black min-h-screen text-white">
                <div className="relative w-full flex justify-center items-center pb-4">
                    <img src={bgLogoHole} alt="" className="absolute animate-pulse inset-0 w-full h-32 z-0"/>
                    <img src={goldenImage} alt="" className="relative h-28 z-20"/>
                </div>
                {/*<hr className="w-full my-1 border-t-2 border-yellow-400" />*/}
                <div className="welcome-banner text-center">
                    <h2 className="text-3xl  font-bold">Welcome to</h2>
                    <h1 className="text-3xl text-yellow-300 font-extrabold">GOLDEN GROUP</h1>
                </div>
                <hr className="w-full my-1 border-t-2 border-yellow-400"/>
                <div className="live-result text-center py-2">
                    <div className="border rounded">
                        <div className=" text-3xl font-bold px-6 py-2 transition-colors">
                            Live Result
                        </div>
                    </div>
                    <div className="mt-8">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                            {upcomingDraws.map(draw => (
                                <div key={draw.id} className="text-center text-white">
                                    <div className="animate-shiny-box">
                                        <div className="border border-yellow-400 p-2 rounded-lg shadow-lg">
                                            <h4 className="text-2xl font-bold font-sans italic text-yellow-400">{draw.drawName}</h4>

                                            {/* Display either fullPatti or number based on the pattern */}
                                            <div className="text-2xl font-bold">
                                                {draw.selectedPattern === "mumbai-pattern" && (
                                                    (() => {
                                                        const mumbaiDraw = todaysDraws[0].mumbaiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id);
                                                        if (mumbaiDraw) {
                                                            const openRoundPatti = mumbaiDraw.openRoundPatti;
                                                            const closeRoundPatti = mumbaiDraw.closeRoundPatti;
                                                            const fullPatti = getFullPatti(openRoundPatti, closeRoundPatti);

                                                            if (fullPatti) {
                                                                return <span>{fullPatti}</span>;
                                                            } else if (openRoundPatti) {
                                                                return <span>{`${openRoundPatti}x-xxx`}</span>;
                                                            } else {
                                                                return <span>xxx-xx-xxx</span>;
                                                            }
                                                        } else {
                                                            return <span>xxx-xx-xxx</span>;
                                                        }
                                                        // Handle case when mumbaiDraw is not found
                                                        return null;
                                                    })()
                                                )}

                                                {draw.selectedPattern === "delhi-pattern" && (
                                                    (() => {
                                                        const delhiDraw = todaysDraws[0].delhiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id);
                                                        if (delhiDraw) {
                                                            const delhiNumber = delhiDraw.number;

                                                            if (delhiNumber) {
                                                                return <span>{delhiNumber}</span>;
                                                            } else {
                                                                return <span>xx</span>;
                                                            }
                                                        } else {
                                                            return <span>xx</span>;
                                                        }
                                                        // Handle case when delhiDraw is not found
                                                    })()
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <hr className="w-full my-8 border-t-2 border-yellow-400"/>

                <div
                    className="p-2 mb-8 font-sans rounded-lg border-sky-200 shadow-[0_0_2px_#ffd700,inset_0_0_2px_#ffd700,0_0_1px_#ffd700,0_0_1px_#ffd700,0_0_30px_#ffd700]">
                    <a
                        href={`https://drive.google.com/file/d/1UL5fjqgAcvJRALZCNMYLYyabxp654y5O/view`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center text-yellow-300 hover:text-yellow-400 transition-colors"
                    >
                        <img src={goldenImage} alt="" className="relative h-20 z-20"/>
                        <div className={"flex flex-row"}>
                        <div className={"p-1 text-3xl font-black capitalize"} style={{letterSpacing: "4px"}}>
                                DOWNLOAD APP
                            </div>
                        </div>

                    </a>
                </div>

                <div
                    className="text-3xl font-bold bg-yellow-400 rounded-lg px-6 py-2 shadow-lg hover:bg-yellow-500 transition-colors">
                    Today's Result
                </div>


                {/* Top Six Draws */}
                <div
                    className="top-six-draws border-2 border-yellow-400 rounded-lg p-10 mb-16 relative overflow-hidden z-0">
                    <div className="absolute animate-fall">
                        <div className="w-3 h-3 rounded-full bg-yellow-300"></div>
                    </div>
                    <div className="grid grid-cols-1 text-white sm:grid-cols-1 lg:grid-cols-3 gap-2 relative z-10">
                        {topSixMumbaiDraws.map(draw => (
                            <div key={draw.id}
                                 className={`draw-box-container bg-cover bg-center p-5 rounded-lg relative`}
                                 style={{backgroundImage: `url('${border}')`}}>
                                <div className="border-t-4 border-yellow-400 mb-2 "></div>
                                <div className="flex flex-col items-center">
                                    <h3 className="text-4xl font-bold mb-4 text-yellow-400 font-sans italic">{draw.drawName}</h3>
                                    <div className="animate-pulse text-4xl font-bold  whitespace-nowrap">
                                        {todaysDraws.length > 0 && todaysDraws[0].mumbaiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id) ? (
                                            <button
                                                className="p-2 font-sans rounded-lg border-sky-200 shadow-[0_0_2px_#ffd700,inset_0_0_2px_#ffd700,0_0_1px_#ffd700,0_0_1px_#ffd700,0_0_30px_#ffd700]"
                                            >
                                                {(getFullPatti(todaysDraws[0].mumbaiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id).openRoundPatti, todaysDraws[0].mumbaiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id).closeRoundPatti))}
                                            </button>
                                        ) : (
                                            <div>
                                                xxx-xx-xxx
                                            </div>
                                        )}
                                    </div>
                                    <div className="pt-3">
                                        <div className="border bg-amber-300 rounded inline-block">
                                            <Link to={`/chart/${draw.id}/false/m`}>
                                                <div className="text-lg font-bold text-black p-1">
                                                    Chart
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="text-lg text-violet-400">
                                        Open Time: {draw.startTime}
                                    </div>
                                    <div className="text-lg text-violet-400">
                                        Close Time: {draw.closingTime}
                                    </div>
                                </div>
                                <div className="border-b-4 border-yellow-400 mt-2"></div>
                            </div>
                        ))}
                    </div>
                </div>

                <hr className="w-full my-8 border-t-2 border-yellow-400"/>

                {/*<button*/}
                {/*    className="p-2 mb-8 font-sans rounded-lg border-sky-200 shadow-[0_0_2px_#ffd700,inset_0_0_2px_#ffd700,0_0_1px_#ffd700,0_0_1px_#ffd700,0_0_30px_#ffd700]"*/}
                {/*>*/}
                {/*    <a href={"/scheme"} className={"font-bold text-5xl"}>*/}
                {/*        Scheme/स्कीम*/}
                {/*    </a>*/}
                {/*</button>*/}

                {/* WhatsApp link */}
                {whatsappNumbersList.map(item => (
                    <div key={item}>
                        <div
                            className="flex flex-col text-2xl w-80 items-center justify-center border border-yellow-400 p-4 rounded-lg mb-8">
                            <a
                                href={`https://wa.me/91${item.number}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="flex items-center justify-center text-2xl text-yellow-300 hover:text-yellow-400 transition-colors"
                            >
                                <img src={whatsAppLogo} alt="WhatsApp Logo" className="w-12 h-12 mr-2"/>
                                <div className={"flex flex-row"}>
                                    <div className={"p-1 capitalize"}>
                                        {item.name}
                                    </div>
                                    <div className={"p-1 items-end"}>
                                        {item.number}
                                    </div>
                                </div>

                            </a>
                        </div>
                    </div>
                ))}

                <div className="flex flex-col font-bold justify-centerr text-5xl">
                    TODAY'S DRAW
                </div>


                {/* Mumbai Today's Result */}
                <div className="todays-result  w-full text-center">
                    {/*<div className="draw-box text-white border border-gray-600 p-1 rounded-lg w-full">*/}
                    {mumbaiPatternDraws.length > 0 && (
                        <div>
                            {mumbaiPatternDraws.map(draw => (
                                <div key={draw.id} className="border-zinc-500 border-t-2">
                                    <div className="">
                                        <div>
                                            <h3 className="text-3xl text-yellow-400 font-bold font-sans italic">{draw.drawName}</h3>
                                            <div
                                                className="text-2xl sm:text-4xl rounded-2xl sm-px-4 font-extrabold">
                                                {todaysDraws.length > 0 && todaysDraws[0].mumbaiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id) ? (
                                                    <button
                                                        className="p-2 font-sans rounded-lg border-sky-200 shadow-[0_0_2px_#ffd700,inset_0_0_2px_#ffd700,0_0_1px_#ffd700,0_0_1px_#ffd700,0_0_30px_#ffd700]"
                                                    >
                                                        {getFullPatti(
                                                            todaysDraws[0].mumbaiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id).openRoundPatti,
                                                            todaysDraws[0].mumbaiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id).closeRoundPatti
                                                        )}
                                                    </button>
                                                ) : (
                                                    <button className="text-4xl">--</button>
                                                )}
                                            </div>
                                            <div className="pt-5">
                                                <div className="border bg-amber-300 rounded inline-block">
                                                    <Link to={`/chart/${draw.id}/false/m`}>
                                                        <div className="text-lg font-bold text-black p-1">
                                                            Chart
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="text-2xl text-violet-400 font-semibold">
                                                {twentyFourHourToTwelveHourCovert(draw.startTime)} - {twentyFourHourToTwelveHourCovert(draw.closingTime)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {/*</div>*/}
                </div>

                {/* Delhi Today's Result */}
                <div className="todays-result w-full text-center">
                    <div className="text-xl bg-yellow-500 text-black font-bold">
                        Disawer,Gali,Faridabad,Ghaziabad Result
                    </div>
                    {/*<div className="draw-box text-white border border-gray-600 p-6 rounded-lg w-full ">*/}
                    {delhiPatternDraws.length > 0 && (
                        <div>
                            {delhiPatternDraws.map(draw => (
                                <div key={draw.id} className="border-zinc-500 border-t-2">
                                    <div>
                                        <h3 className="text-3xl text-yellow-400 font-bold italic">{draw.drawName}</h3>
                                        <div className="text-4xl font-extrabold">
                                            {todaysDraws.length > 0 && todaysDraws[0].delhiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id) ? (
                                                <button
                                                    className="p-2 font-sans rounded-lg border-sky-200 shadow-[0_0_2px_#ffd700,inset_0_0_2px_#ffd700,0_0_1px_#ffd700,0_0_1px_#ffd700,0_0_30px_#ffd700]"
                                                >
                                                    {todaysDraws[0].delhiDraws.find(todaysDraw => todaysDraw.idDrawName === draw.id).number}
                                                </button>
                                            ) : (
                                                <div className="text-4xl">--</div>
                                            )}
                                        </div>
                                        <div className="pt-5">
                                            <div className="border bg-amber-300 rounded inline-block">
                                                <Link to={`/chart/${draw.id}/false/d`}>
                                                    <div className="text-lg font-bold text-black p-1">
                                                        Chart
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="text-2xl text-violet-400 font-semibold">
                                            {twentyFourHourToTwelveHourCovert(draw.startTime)}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    {/*</div>*/}
                </div>

            </div>
            <button
                className="fixed bottom-4 right-4 text-3xl text-white font-bold bg-emerald-900 rounded-lg px-4 py-1 shadow-lg hover:bg-emerald-600 transition-colors"
                onClick={() => {
                    refreshData()
                }}
            >
                Refresh
            </button>
            <div className={"fixed bottom-4 left-4"}>
                <div
                    className="text-3xl text-white font-bold bg-emerald-900 rounded-lg px-4 py-1 shadow-lg hover:bg-emerald-600 mb-3 transition-colors">
                    <div className="whatsapp-link text-center">
                        <a
                            href="https://t.me/GOLDEN_BOMBAY_MATKA"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-center text-2xl text-yellow-300 hover:text-yellow-400 transition-colors"
                        >
                            <img src={telegramLogo} alt="WhatsApp Logo" className="w-12 h-12 mr-2"/>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export const getFullPatti = (open, close) => {
    const openNumber = parseInt(open, 10);
    const closeNumber = parseInt(close, 10);

    const openDigits = openNumber.toString().split('').map(Number);
    const closeDigits = closeNumber.toString().split('').map(Number);

    const openSum = openDigits.reduce((a, b) => a + b, 0);
    const closeSum = closeDigits.reduce((a, b) => a + b, 0);

    const openLastDigit = openSum.toString().slice(-1);
    const closeLastDigit = closeSum.toString().slice(-1);

    if (!close) {
        return `${open} - ${openLastDigit}x - xxx`;
    } else if (open && close) {
        return `${open} - ${openLastDigit}${closeLastDigit} - ${close}`;
    } else {
        return '';
    }
}